import { graphql } from "gatsby"
import Img from "gatsby-image"
import { css, cx } from "linaria"
import { styled } from "linaria/react"
import { transparentize } from "polished"
import React from "react"
import Layout from "../components/layout"
import { bigHeader } from "../components/Layout/contentHeader"
import MaxWidth from "../components/Layout/maxWidth"
import SEO from "../components/seo"
import colors from "../styles/colors"
import Card from "../components/Blog/card"

const IndexPage = props => {
  const fluid = props.data.file.childImageSharp.fluid
  const latestPost = props.data.allMdx.nodes[0]

  return (
    <Layout>
      <SEO title="Home" keywords={[`inprosperis`]} />
      <Hero>
        <Img fluid={fluid} />
        <div class="inner">
          <MaxWidth>
            <div className="center">
              <h1>Inprosperis - investigate & invent</h1>
            </div>
          </MaxWidth>
        </div>
      </Hero>

      <MaxWidth>
        <h2 className={cx(bigHeader, header)}>
          Meaningful research & technology development for customer's benefit
        </h2>
      </MaxWidth>

      <MaxWidth style={{ maxWidth: "40rem" }}>
        <h2 className={cx(bigHeader, header)}>Latest news</h2>
        <Card post={latestPost} featured />
      </MaxWidth>
    </Layout>
  )
}

const Hero = styled.div`
  color: white;
  min-height: 300px;
  height: 40vh;
  overflow: hidden;
  position: relative;
  .gatsby-image-wrapper {
    max-height: 50vh;
    height: 100%;
  }
  h1 {
    padding: 0;
    margin: 0;
  }
  .inner {
    background-color: ${transparentize(0.7, colors.darkBlue)};
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    > * {
      height: 100%;
    }
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`

const header = css`
  text-align: center;
  margin-top: 3em;
  font-size: 1.75em;
`

export default IndexPage

export const query = graphql`
  {
    allMdx(limit: 1, sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      nodes {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "DD MMMM, YYYY")
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "DSC07172-1920x1080.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
