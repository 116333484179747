import { graphql, Link, useStaticQuery } from "gatsby"
import { css } from "linaria"
import { transparentize } from "polished"
import React from "react"
import colors from "../../styles/colors"

const nav = css`
  font-size: 16px;
  font-weight: normal;
  margin-left: auto;
  ul {
    margin: 0 -1em;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    :hover .active {
      border-top-color: ${transparentize(0.7, colors.darkBlue)};
      :hover {
        border-top-color: ${colors.darkBlue};
      }
    }
  }
  li {
    padding: 0;
    margin: 0;
    a {
      padding: 0.5em;
      margin: 0 0.25em;
      color: #535354;
      text-decoration: none;
      border-top: 2px solid transparent;
      transition: all 0.4s ease;
      :hover {
        color: black;
        border-top-color: ${colors.darkBlue};
      }
    }
    a.active {
      border-top-color: ${colors.darkBlue};
    }
  }
  h2 {
    display: none;
  }
  @media (max-width: 800px) {
    display: none;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    position: fixed;
    background: white;

    h2 {
      position: fixed;
      z-index: 20;
      top: 0;
      left: 0;
      background: ${colors.darkBlue};
      color: white;
      text-align: center;
      width: calc(100% - 2em);
      margin: 0;
      padding: 1em;
      display: flex;

      button {
        margin-left: auto;
        background: transparent;
        border: 0;
        color: white;
        font-size: 0.6em;
        :focus {
          outline: none;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      li {
        width: 100%;
        text-align: center;
      }

      a {
        display: block;
        padding: 1rem;
        border-color: transparent !important;
        :hover,
        :focus {
          border-color: transparent !important;
        }
      }
    }
  }
`

const burger = css`
  display: none;
  margin-left: auto;
  margin-right: 2rem;
  border: 1px solid ${transparentize(0.8, colors.darkBlue)};
  cursor: pointer;
  padding: 0.5em 1em;
  background: transparent;
  :focus {
    outline: none;
  }
  @media (max-width: 800px) {
    display: inline-block;
  }
`

export default function Navigation() {
  const [menuOpen, setMenuOpen] = React.useState(false)

  const data = useStaticQuery(graphql`
    query NavQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  return (
    <>
      {menuOpen || (
        <button className={burger} onClick={() => setMenuOpen(!menuOpen)}>
          Menu
        </button>
      )}
      <nav className={nav} style={menuOpen ? { display: "flex" } : {}}>
        <h2 onClick={() => setMenuOpen(false)}>
          Menu
          <button>Close</button>
        </h2>
        <ul>
          {data.site.siteMetadata.menuLinks.map(navItem => (
            <li>
              <Link to={navItem.link} activeClassName="active">
                {navItem.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}
