import { css } from "linaria"
import colors from "../../styles/colors"

const bigHeader = css`
  font-size: 2em;
  color: ${colors.darkBlue};
  font-weight: 700;
  margin-top: 0;
`

export { bigHeader }
