import React from "react"
import footerImage from "../../images/DSC07172_1920_1080_footer.jpg"
import { css } from "linaria"
import colors from "../../styles/colors"
import { transparentize } from "polished"

const footer = css`
  margin-top: auto;
  font-size: 0.75em;
  text-align: center;
  height: 6rem;
  display: flex;
  align-items: center;
  background: white;
  background-image: url(${footerImage});
  background-repeat: no-repeat;
  color: white;
  background-size: cover;
  p {
    text-align: center;
    padding: 0 1em;
    margin: 0 auto;
    font-weight: 600;
    letter-spacing: 0.03em;
  }
  .footer-inner {
    background: ${transparentize(0.5, colors.darkBlue)};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default function Footer() {
  return (
    <footer className={footer}>
      <div className="footer-inner">
        <p>
          © {new Date().getFullYear()} | Inprosperis e.U. | Schneeburggasse 39 -
          Top 1 | A - 6020 Innsbruck | AUSTRIA | +43 699 1920 3322 |
          laco.kacani@inprosperis.eu
        </p>
      </div>
    </footer>
  )
}
