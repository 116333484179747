import React from "react"
import { css } from "linaria"
import { darken } from "polished"

export default function HR({ m = "1em auto", ...props }) {
  return <hr className={hr} style={{ margin: m }} {...props} />
}

const hr = css`
  border: 0;
  border-bottom: 1px solid ${darken(0.1, "#fafafb")};
  height: 0;
  background: transparent;
  display: block;
`
