import React from "react"
import { css } from "linaria"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const wrapper = css`
  && img {
    max-width: 17rem;
  }
`

export default function Logo({ siteTitle }) {
  const logo = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className={wrapper}>
      <Img
        critical={true}
        fixed={logo.file.childImageSharp.fixed}
        alt={siteTitle}
      />
    </div>
  )
}
