import { Link } from "gatsby"
import { css, cx } from "linaria"
import { darken, lighten } from "polished"
import React from "react"
import colors from "../../styles/colors"
import HR from "../Layout/hr"
import Img from "gatsby-image"

export default function Card({ m = 3, post, featured = false, ...props }) {
  const {
    frontmatter: { date, title, featuredImage },
    excerpt,
    fields: { slug },
  } = post

  const fluid = featuredImage && featuredImage.childImageSharp.fluid

  return (
    <article
      className={cx(card, featured && "featured", props.className)}
      style={{ marginBottom: `${m}em` }}
    >
      <header>
        <Link to={slug}>
          {fluid && <Img fluid={fluid} />}
          <h1>{title}</h1>
        </Link>
      </header>

      <HR m={"0 0 1em 0"} />

      {!featured && (
        <div className={dateStyle}>
          Posted on: <strong>{date}</strong>
        </div>
      )}

      <div className={excerptStyle}>
        <p>{excerpt}</p>
        <Link to={slug}>Read more…</Link>
      </div>
    </article>
  )
}

const card = css`
  border: 1px solid ${darken(0.1, "#fafafb")};
  border-radius: 3px;
  padding-bottom: 1rem;
  overflow: hidden;

  img {
    transition: all 0.3s ease !important;
  }

  :hover img {
    transform: scale(1.1);
  }

  &.featured {
    border-radius: 6px;
    border: 0;
    box-shadow: 0 1px 12px ${darken(0.1, "#fafafb")};

    h1 {
      font-size: 1.25em;
    }
  }

  header {
    h1 {
      font-size: 1.5em;
      padding: 1em;
      margin: 0;
      text-align: center;
    }

    a {
      text-decoration: none;
      color: ${colors.darkBlue};
      :hover {
        color: ${lighten(0.2, colors.darkBlue)};
      }
    }
  }
`
const dateStyle = css`
  font-size: 0.75em;
  padding: 0 1rem;
`
const excerptStyle = css`
  padding: 0 1rem;
`
