import React from "react"
import { css } from "linaria"

export const maxwidth = {
  margin: "0 auto",
  maxWidth: "65rem",
  padding: "1em",
  img: {
    maxWidth: "100%",
  },
}

const wrapper = css`
  ${maxwidth};
`

export default function MaxWidth({ children, ...rest }) {
  return (
    <section className={wrapper} {...rest}>
      {children}
    </section>
  )
}
