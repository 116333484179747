import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navigation from "./navigation"
import { css } from "linaria"
import Logo from "./logo"
import { maxwidth } from "../Layout/maxWidth"

const header = css`
  background: white;
  ${maxwidth};
  padding-left: 0;
  padding-right: 0;

  h1 {
    margin: 0;
  }

  .wrap {
    display: flex;
    align-items: center;

    @media (max-width: 1060px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      nav {
        margin: auto;
        margin-top: 1em;
      }
    }

    @media (max-width: 800px) {
      flex-direction: row;
      justify-content: flex-start;
      h1 {
        margin-right: auto;
        margin-left: 2rem;
      }
      img {
        max-height: 8rem;
      }
    }
  }
`

const Header = ({ siteTitle }) => (
  <header className={header}>
    <div className="wrap">
      <h1>
        <Link
          to="/"
          style={{
            textDecoration: `none`,
          }}
        >
          <Logo siteTitle={siteTitle} />
        </Link>
      </h1>
      <Navigation />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
