/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, StaticQuery } from "gatsby"
import { css } from "linaria"
import { normalize, lighten } from "polished"
import PropTypes from "prop-types"
import React from "react"
import Header from "./Header"
import Footer from "./Layout/footer"
import colors from "../styles/colors"

const global = css`
  :global() {
    ${normalize()};
    * {
      font-family: "Open Sans", sans-serif;
    }
    body,
    html {
      line-height: 1.5;
      height: 100%;
    }

    a {
      color: ${colors.darkBlue};
      :hover {
        color: ${lighten(0.2, colors.darkBlue)};
      }
    }
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={container}>
        <section style={{ marginBottom: "2em" }}>
          <Header siteTitle={data.site.siteMetadata.title} />
          {children}
        </section>
        <Footer />
      </div>
    )}
  />
)

const container = css`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
